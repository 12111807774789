import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import ServiceAnchorsNavigation from '../modules/service-anchors-navigation'
import StackCards from '../animations/stack-cards'
import SwipeCards from '../animations/swipe-cards'

export default class SingleCity extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter());
    })
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeader'),
      cityChangementsSection: viewStorage.current.querySelector('.CityChangements'),
      newServicesSection: viewStorage.current.querySelector('.NewServices')
    } 

    if (this.DOM.pageHeader) this.serviceAnchorsNavigationModule = new ServiceAnchorsNavigation({ section: this.DOM.pageHeader, offsetY: 100 })
    if (this.DOM.cityChangementsSection) this.stackCardModule = new StackCards({ section: this.DOM.cityChangementsSection })
    if (this.DOM.newServicesSection) this.swipeCardsModule = new SwipeCards({ section: this.DOM.newServicesSection })
  }

  onLeaveCompleted() {
    if (this.serviceAnchorsNavigationModule) this.serviceAnchorsNavigationModule.destroy()
    if (this.stackCardModule) this.stackCardModule.destroy()
    if (this.swipeCardsModule) this.swipeCardsModule.destroy()
  }
}
