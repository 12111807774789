/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage, modulesStorage, animationsStorage } from './_globals'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'

import Autocomplete from './modules/autocomplete'

import PopContent from './animations/inview/pop-content'
import SearchCommune from './animations/inview/search-commune'

/* --- DOMContentLoaded Function --- */
export const onReady = () => {
  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(domStorage.body)
  viewStorage.viewInView = new viewInView(domStorage.body)

  // Intro
  GlobalIntro()

  // General modules
  modulesStorage.autocomplete = new Autocomplete()

  // General animations
  animationsStorage.pop_content = new PopContent()
  animationsStorage.search_commune = new SearchCommune()
}

export const onEnter = (to, trigger) => {
  // ScrollTop
  // window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  domStorage.body.classList.remove('--loading')

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  viewStorage.viewScroll.scroll.start()

  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()

}

export const onEnterCompleted = (to, from, trigger) => {
  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': globalStorage.taxi.targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': globalStorage.taxi.targetLocation.href
    })
  }

  // Anchor
  if (globalStorage.taxi.targetLocation.hasHash) {
    if (document.querySelector(window.location.hash)) {
      gsap.to(window, {
        scrollTo: { 
          y: window.location.hash, 
          autoKill: false 
        }, 
        overwrite: 'all', 
        ease: 'expo.inOut',
        duration: 1.8, 
      })
    }
  }

  // General modules
  modulesStorage.autocomplete = new Autocomplete()

  // General animations
  animationsStorage.pop_content = new PopContent()
  animationsStorage.search_commune = new SearchCommune()
}

export const onLeave = (from, trigger) => {
  if (viewStorage.hasInview) viewStorage.viewInView.destroy()
  if (viewStorage.hasPrllx)  viewStorage.viewPrllx.destroy()

  domStorage.body.classList.add('--loading')
  domStorage.body.classList.add('--animating')

  // Close Menu
  if (globalStorage.menuOpen) globalStorage.closeMobileMenu()
  
  // Remove active links
  const navLinks = domStorage.menu.querySelectorAll('.Nav .Link')

  navLinks.forEach((link) => {
    if (link.href === globalStorage.taxi.targetLocation.raw) {
      link.classList.add('--active')
    } else {
      link.classList.remove('--active')
    }
  })
  
  // General modules
  if (modulesStorage.autocomplete) modulesStorage.autocomplete.destroy()

  // General animations
  if (animationsStorage.pop_content) animationsStorage.pop_content.destroy()
  if (animationsStorage.search_commune) animationsStorage.search_commune.destroy()
}
