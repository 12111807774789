import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import Accordions from '../modules/accordions'

export default class PageFAQ extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter());
    })
  }

  onEnter() {
    this.DOM = {
      faqQuestionsSection: viewStorage.current.querySelector('.FaqQuestions')
    }

    if (this.DOM.faqQuestionsSection) this.accordionsModule = new Accordions(this.DOM.faqQuestionsSection)
  }

  onLeaveCompleted() {
    if (this.accordionsModule) this.accordionsModule.destroy()
  }
}
