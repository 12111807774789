import { domStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { pageIntro } from '../animations/page-intro'

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    const tl = gsap.timeline({
      onComplete: () => done()
    })
    
    tl.fromTo(domStorage.loader, { opacity: 1 }, { opacity: 0 }, 0)
    tl.add(pageIntro(0), 0)

    return tl
  }

  onLeave({ from, trigger, done }) {
    const tl = gsap.timeline({
      defaults: { duration: 0.4, ease: 'power4.in' },
      onStart: () => {
        domStorage.body.classList.add('--loading')
        domStorage.body.classList.add('--animating')
      },
      onComplete: () => done()
    })

    tl.to(domStorage.header, { yPercent: -30, opacity: 0, ease: 'expo.out', duration: 1 }, 0)
    tl.fromTo(domStorage.loader, { opacity: 0 }, { opacity: 1 }, 0)

    return tl
  }
}
