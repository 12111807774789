import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class ServiceAnchorsNavigation {
  constructor({ section, offsetY }) {
    this.DOM = { section }
    this.DOM.buttons = [...this.DOM.section.querySelectorAll('button.ServiceAnchorButton')]
    this.DOM.serviceAnchors = [...viewStorage.current.querySelectorAll('.ServiceAnchor')]

    this.offsetY = offsetY

    if (!this.DOM.buttons.length && !this.DOM.serviceAnchors.length) return

    this.onClick = this.onClick.bind(this)
    this.DOM.buttons.forEach(button => button.addEventListener('click', this.onClick))
  }

  onClick(e) {
    e && e.preventDefault()
    
    const index = this.DOM.buttons.indexOf(e.currentTarget)
    const relatedServiceCard = this.DOM.serviceAnchors[index]

    gsap.to(window, { 
      // scrollTo: relatedServiceCard, 
      scrollTo: { 
        y: relatedServiceCard, 
        // offsetY: this.offsetY ?? null
        offsetY: (this.offsetY && window.innerWidth >= 768) && this.offsetY
      }, 
      ease: 'expo.inOut', 
      duration: 2.5 
    })
  }

  destroy() {
    if (this.DOM.buttons && this.DOM.buttons.length) this.DOM.buttons.forEach(button => button.removeEventListener('click', this.onClick))
  }
}