import { viewStorage, domStorage } from '../../_globals'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(SplitText, ScrollTrigger)

export default class PopContent {
  constructor() {
    this.DOM = { sections: viewStorage.current.querySelectorAll('.--pop-content') }

    if (!this.DOM.sections && !this.DOM.sections.length) return

    this.DOM.sections.forEach(section => {
      const title = section.querySelector('h2')
      const content = section.querySelectorAll('.content')
      const more = section.querySelectorAll('.more')

      this.setAnimation({
        section,
        title,
        content,
        more
      })
    })
  }

  setAnimation({ section, title, content, more }) {
    if (title) {
      title.splitText = new SplitText(title, { type: 'chars,lines' })
    }

    // Timeline
    section.tl = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 1 },
      onComplete: () => {
        title.splitText.revert()
        gsap.set(title, { clearProps: 'all' })
      }
    })

    if (title) {
      section.tl.from(title.splitText.chars, { 
        y: 50, 
        scale: 0, 
        stagger: { each: 0.01, from: 'random' }, 
        duration: 1, 
        ease: 'elastic.out(1,0.8)',
      }, 0)
    }

    if (content && content.length) {
      section.tl.from(content, { 
        y: 20,
        opacity: 0,
        clearProps: 'y,opacity'
      }, '<')
    }

    if (more && more.length) {
      section.tl.from(more, { 
        y: 20,
        opacity: 0,
        clearProps: 'y,opacity'
      }, '<')
    }

    // ScrollTrigger
    section.scrollTrigger = new ScrollTrigger({
      trigger: section,
      start: 'top 60%',
      end: 'bottom top',
      animation: section.tl
    })
  }

  destroy() {
    if (!this.DOM.sections && !this.DOM.sections.length) return

    this.DOM.sections.forEach(section => {
      if (section.tl) section.tl.kill()
      if (section.scrollTrigger) section.scrollTrigger.kill() 
    })
  }
}