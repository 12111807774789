/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import FrontPage from './pages/front-page'
import Page from './pages/page'
import PageFAQ from './pages/page-faq'
import PageDiscoverNetwork from './pages/page-discover-network'
import SingleCity from './pages/single-city'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)',
  renderers: {
    default: Page,
    frontPage: FrontPage,
    pageDiscoverNetwork: PageDiscoverNetwork,
    pageFAQ: PageFAQ,
    singleCity: SingleCity
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
