import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class SwiperCards {
  constructor({ section }) {
    this.DOM = { section }
    this.DOM.grid = this.DOM.section.querySelector('.grid')
    this.DOM.cardsContainer = this.DOM.section.querySelectorAll('.SwipeCardContainer')
    this.DOM.cards = this.DOM.section.querySelectorAll('.SwipeCard')

    if (!this.DOM.cards.length) return

    this.setAnimation()
  }

  setAnimation() {
    const { 
      cardsContainer,
      cards
    } = this.DOM

    cardsContainer.forEach((container, i) => {
      const card = cards[i]

      container.tween = gsap.from(card, {
        scale: 0.25,
        xPercent: 100,
        yPercent: 30,
        opacity: 0,
        rotate: -10,
        ease: 'none',

        scrollTrigger: {
          id: i + 1,
          trigger: container,
          scrub: 0.5,
          start: 'top 80%',
          end: 'top 30%',
        }
      })
    })
  }

  destroy() {
    if (this.DOM.cardsContainer && this.DOM.cardsContainer.length) this.DOM.cardsContainer.forEach(c => c.tween.kill())
  }
}
