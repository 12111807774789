import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, SplitText, DrawSVGPlugin)

/**
 * All animations for different parts of the DOM
 */

export const header = () => {
  if (!domStorage.header) return

  const tween = gsap.fromTo(domStorage.header, {
    yPercent: -30,
    opacity: 0,
  }, {
    yPercent: 0,
    opacity: 1,
    ease: 'expo.inOut',
    duration: 1.5,
    onComplete: () => {
      gsap.set(domStorage.header, { clearProps: 'yPercent,scale,opacity' })
    }
  })

  return tween
}

export const basicIntro = () => {
  const h1 = viewStorage.current.querySelector('h1')
  const children = [...viewStorage.current.querySelectorAll('.container > *:not(.head)')]

  const tl = gsap.timeline({
    defaults: { ease: 'expo.inOut', duration: 1.5 },
    onComplete: () => {
      if (h1) gsap.set(h1, { clearProps: 'y,opacity' })
      if (children) gsap.set(children, { clearProps: 'y,opacity' })
    }
  })

  if (h1) tl.from(h1, { y: 100, opacity: 0 }, 0)
  if (children) tl.from(children, { y: 100, opacity: 0 }, 0.035)

  return tl
}

export const pageHeaderIntro = () => {
  const { current } = viewStorage
  const pageHeader = current.querySelector('.PageHeader')

  if (!pageHeader) return

  /**
   * DOM
   */
  const subtitle = pageHeader.querySelector('.container > .subtitle')
  const h1 = pageHeader.querySelector('h1')
  const networkBackground = pageHeader.querySelector('.visual__container.--network .visual')
  const button = pageHeader.querySelector('.container > .Btn')

	// const banner = current.querySelector('.PageHeader + .Banner')
  const sections = [...viewStorage.current.children].slice(1)

  /**
   * SplitText
   */
  const subtitleChars = new SplitText(subtitle, { type: 'chars,lines' })
  const h1Chars = new SplitText(h1, { type: 'chars,lines' })

  /**
   * Main Timeline
   */
  const tl = gsap.timeline({
    defaults: { ease: 'power3.inOut', duration: 1 },
    onComplete: () => {
      // Clear SplitText
      if (subtitle) subtitleChars.revert()
      if (h1) h1Chars.revert()
    }
  })

  /**
   * General
   */

  if (subtitle) {
    tl.from(subtitleChars.chars, { 
      y: 100, 
      scale: 0, 
      stagger: { each: 0.01, from: 'random' }, 
      duration: 1, 
      ease: 'elastic.out(1,0.8)' 
    }, 0)
  }

  if (h1) {
    tl.from(h1Chars.chars, { 
      y: 100, 
      scale: 0, 
      stagger: { each: 0.01, from: 'random' }, 
      duration: 1, 
      ease: 'elastic.out(1,0.8)'
    }, 0)
  }

  if (networkBackground) {
    tl.from(networkBackground, { 
      scale: 1.15, 
      opacity: 0 
    }, '<')
  }

  if (button) {
    tl.from(button, { 
      y: 20, 
      opacity: 0, 
      clearProps: 'y,opacity' 
    }, '-=1')
  }

  /**
   * Pages
   */

  // Page: Home
  if (current.classList.contains('Home')) {
    const upItems = pageHeader.querySelectorAll('.NewNetwork *.--up')
    const visual = pageHeader.querySelector(':scope .wrapper > .visual__container')
    const form = pageHeader.querySelector('.FormSearchCommune')

    if (upItems.length) {
      tl.from(upItems, {
        y: 20,
        opacity: 0,
        stagger: 0.15,
        clearProps: 'y,opacity'
      }, 0)
    }

    if (visual) {
      tl.from(visual, {
        scale: 1.05,
        opacity: 0,
        clearProps: 'scale,opacity'
      }, '<')
    }

    if (form) {
      tl.from(form, {
        y: 150,
        opacity: 0,
        clearProps: 'y,opacity'
      }, 0.5)
    }

    if (sections && sections.length) {
      tl.from(sections, {
        y: 20, 
        opacity: 0,
        clearProps: 'y,opacity'
      })
    }
  }

  // Page: Discover Network
  if (current.classList.contains('DiscoverNetwork')) {
    const slides = current.querySelectorAll('.swiper-slide > *')
    const swiperNav = current.querySelector('.swiper-navigation')

    if (slides && slides.length) {
      tl.from(slides, { 
        x: 100, 
        opacity: 0, 
        stagger: { each: 0.07 }, 
        clearProps: 'x,opacity' 
      }, '-=0.8')
    }

    if (sections && sections.length) {
      tl.from(sections, {
        y: 20, 
        opacity: 0,
        clearProps: 'y,opacity'
      }, '<')
    }

    if (swiperNav) {
      tl.from(swiperNav, {
        y: 20,
        opacity: 0,
        clearProps: 'y,opacity'
      }, '-=0.7')
    }
  }

  // Page: FAQ
  if (current.classList.contains('FAQ')) {
    const faqQuestions = current.querySelectorAll('.FaqQuestions .Accordion')

    if (faqQuestions && faqQuestions.length) {
      tl.from(faqQuestions, { 
        y: 65, 
        opacity: 0, 
        stagger: 0.15, 
        clearProps: 'y,opacity' 
      })
    }

    if (sections && sections.length) {
      tl.from(sections, {
        y: 20, 
        opacity: 0,
        clearProps: 'y,opacity'
      })
    }
  }

  // Page: City
  if (current.classList.contains('City')) {
    const city = pageHeader.querySelector('.city')
    const link = pageHeader.querySelector('.Link')
    const sectorFeatures = pageHeader.querySelector('.SectorFeatures')

    if (city) {
      tl.from(city, { 
        scale: 1.15, 
        opacity: 0, 
        y: 50, 
        rotate: 12, 
        duration: 0.75, 
        clearProps: 'scale,opacity,y' 
      }, 0.3)
    }

    if (link){
      tl.from(link, { 
        opacity: 0, 
        y: 20, 
        clearProps: 'opacity,y' 
      }, 0.4)
    }

    if (sectorFeatures) {
      tl.from(sectorFeatures, { 
        opacity: 0, 
        y: -50, 
        clearProps: 'opacity,y' 
      }, 1)
    }

    if (sections && sections.length) {
      tl.from(sections, {
        y: 20, 
        opacity: 0,
        clearProps: 'y,opacity'
      }, 1)
    }
  }



  return tl
}

// Page: Basic
export const pageSections = () => {
  const sections = [...viewStorage.current.children].slice(1)

  if (!sections && !sections.length) return

  const tween = gsap.from(sections, { 
    y: 20, 
    opacity: 0, 
    ease: 'power3.out', 
    duration: 1, 
    onComplete: () => gsap.set(sections, { clearProps: 'y,opacity' }) 
  })

  return tween
}
