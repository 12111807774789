import { viewStorage, domStorage } from '../../_globals'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(SplitText, ScrollTrigger)

export default class SearchCommune {
  constructor() {
    this.DOM = { section: viewStorage.current.querySelector('.SearchCommune') }

    if (!this.DOM.section) return

    this.DOM.h2 = this.DOM.section.querySelector('h2')
    this.DOM.form = this.DOM.section.querySelector('.FormSearchCommune')

    if (this.DOM.h2) {
      this.DOM.h2Chars = new SplitText(this.DOM.h2, { type: 'chars,lines' })
    }

    this.setAnimation()
  }

  setAnimation() {
    // Timeline
    this.tl = gsap.timeline({
      defaults: { ease: 'expo.inOut', duration: 1.5 },
      onComplete: () => {
        this.DOM.h2Chars.revert()
      }
    })
    
    if (this.DOM.h2Chars) {
      this.tl.from(this.DOM.h2Chars.chars, { 
        y: 100, 
        scale: 0, 
        stagger: { each: 0.01, from: 'random' }, 
        duration: 1, 
        ease: 'elastic.out(1,0.8)'
      }, 0)
    }

    if (this.DOM.form) {
      this.tl.from(this.DOM.form, { 
        y: 100, 
        scale: 0.75, 
        opacity: 0,
        clearProps: 'y,scale,opacity'
      }, 0)
    }

    // ScrollTrigger
    this.scrollTrigger = new ScrollTrigger({
      trigger: this.DOM.section,
      start: 'top 70%',
      end: 'bottom top',
      animation: this.tl
    })
  }

  destroy() {
    if (this.DOM.h2Chars) this.DOM.h2Chars.revert()
    if (this.tl) this.tl.kill()
    if (this.scrollTrigger) this.scrollTrigger.kill()
  }
}