import { isDEVMODE, domStorage, viewStorage } from '../_globals'

export default class Autocomplete {
  constructor() {
    this.DOM = {
      form: viewStorage.current.querySelector('.FormSearchCommune'),
      input: viewStorage.current.querySelector('#autocomplete-input'),
      autocompleteList: viewStorage.current.querySelector('#autocomplete-list'),
      button: viewStorage.current.querySelector('.FormSearchCommune button')
    }

    if (!this.DOM.input && !this.DOM.autocompleteList) return

    // Events
    this.onInput = this.onInput.bind(this)
    this.onDocumentClick = this.onDocumentClick.bind(this)
    
    this.DOM.form.addEventListener('submit', this.onSubmit)
    this.DOM.input.addEventListener('input', this.onInput)
    document.addEventListener('click', this.onDocumentClick)
  }

  onSubmit(e) {
    e && e.preventDefault()
  }

  onInput(e) {
    const { input, autocompleteList } = this.DOM
    const choices = global_data.cities

    const query = e.currentTarget.value.toLowerCase()
    autocompleteList.classList.remove('--no-result')
    autocompleteList.innerHTML = ''

    if (!query) return false

    let hasResults = false
    
    choices.forEach(choice => {
      let wordsFound = 0

      choice.title = choice.title.normalize("NFD").replace(/[\u0300-\u036f]/g, '')

      query.replaceAll('-', ' ').trim().split(' ').forEach((word) => {
        if (word == 'st') {
          word = 'saint'
        }

        word = word.normalize("NFD").replace(/[\u0300-\u036f]/g, '')

        if (choice.title.toLowerCase().includes(word)) {
          wordsFound++
        }
      })

      if (query.replaceAll('-', ' ').trim().split(' ').length == wordsFound) {
        hasResults = true

        const item = document.createElement('a')
        item.href = choice.url
        item.textContent = choice.title
        
        autocompleteList.appendChild(item)
      }
    })

    if (!hasResults) {
      !autocompleteList.classList.contains('--no-result') && autocompleteList.classList.add('--no-result')
      autocompleteList.innerHTML = `<div class="no-result">Votre commune n'a pas été trouvée.</div>`
    }
  }

  onDocumentClick(e) {
    if (!this.DOM.autocompleteList.contains(e.target) && e.target !== this.DOM.input && e.target !== this.DOM.button) {
      this.DOM.autocompleteList.innerHTML = ''
    }
  }

  destroy() {
    if (!this.DOM.input && !this.DOM.autocompleteList) return

    this.DOM.form.removeEventListener('submit', this.onSubmit)
    this.DOM.input.removeEventListener('input', this.onInput)
    document.removeEventListener('click', this.onDocumentClick)
  }
}
