import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import ServiceAnchorsNavigation from '../modules/service-anchors-navigation'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import ServiceVisualZoomPopup from '../modules/service-visual-zoom-popup'

export default class PageDiscoverNetwork extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter());
    })
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderDiscoverNetwork'),
      pageHeaderSwiper: viewStorage.current.querySelector('.PageHeaderDiscoverNetwork .swiper'),
      mobileSwipers: viewStorage.current.querySelectorAll('.swiper.--mobile'),
      serviceGalleryCards: viewStorage.current.querySelectorAll('.ServiceGalleryCard')
    } 

    if (this.DOM.pageHeader) this.ServiceAnchorsNavigationModule = new ServiceAnchorsNavigation({ section: this.DOM.pageHeader })

    if (this.DOM.pageHeaderSwiper) {
      this.pageHeaderSwiperModule = new WknSwiper(this.DOM.pageHeaderSwiper, {
        slidesPerView: 'auto',
        centerInsufficientSlides: true, 
        spaceBetween: 10
      })
    }

    if (this.DOM.mobileSwipers && this.DOM.mobileSwipers.length) this.DOM.mobileSwipers.forEach(s => s.module = new MobileSwiper(s))

    if (this.DOM.serviceGalleryCards && this.DOM.serviceGalleryCards.length) this.serviceVisualZoomPopupModule = new ServiceVisualZoomPopup({ cards: this.DOM.serviceGalleryCards })
  }

  onLeaveCompleted() {
    if (this.ServiceAnchorsNavigationModule) this.ServiceAnchorsNavigationModule.destroy()
    if (this.pageHeaderSwiperModule) this.pageHeaderSwiperModule.destroy()
    if (this.DOM.mobileSwipers && this.DOM.mobileSwipers.length) this.DOM.mobileSwipers.forEach(s => s.module && s.module.destroy())
    if (this.serviceVisualZoomPopupModule) this.serviceVisualZoomPopupModule.destroy()
  }
}
