import { isDEVMODE, viewStorage, domStorage } from '../_globals'

export default class ServiceVisualZoomPopup {
  constructor({ cards }) {
    // DOM
    this.DOM = { 
      cards,
      popup: viewStorage.current.querySelector('.ServiceVisualZoomPopup'),
      popupClose: viewStorage.current.querySelector('.ServiceVisualZoomPopup .Close')
    }

    if (!this.DOM.cards && !this.DOM.cards.length) return

    // Events
    this.openPopup = this.openPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)

    this.DOM.cards.forEach(card => card.addEventListener('click', this.openPopup))
    this.DOM.popupClose.addEventListener('click', this.closePopup)
  }

  openPopup(e) {
    e && e.preventDefault()

    const cardThumbnail = e.currentTarget.querySelector('.visual__container .visual img')

    this.DOM.img = document.createElement('img')
    this.DOM.img.src = cardThumbnail.src
    this.DOM.img.alt = cardThumbnail.alt
    this.DOM.img.className = '--br-20'

    this.DOM.popup.querySelector('.inner').appendChild(this.DOM.img)

    domStorage.body.classList.add('--show-service-popup')
  }

  closePopup(e) {
    e && e.preventDefault()

    if (!this.DOM.img) return

    domStorage.body.classList.remove('--show-service-popup')
    
    this.DOM.img.remove()
  }

  destroy() {
    if (!this.DOM.cards && !this.DOM.cards.length) return

    this.DOM.cards.forEach(card => card.removeEventListener('click', this.openPopup))
    this.DOM.popupClose.removeEventListener('click', this.closePopup)
  }
}
