import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class StackCards {
  constructor({ section }) {
    this.DOM = { section }  
		this.DOM.grid = this.DOM.section.querySelector('.grid')
    this.DOM.cardsContainer = this.DOM.section.querySelectorAll('.StackCardContainer')
    this.DOM.cards = this.DOM.section.querySelectorAll('.StackCard')

    if (!this.DOM.cards.length) return

		// MatchMedia variable
		this.mm = gsap.matchMedia()
		// Breakpoint
		this.breakpoint = 768
		// Responsive object
		this.responsiveObj = {
			isDesktop: `(min-width: ${this.breakpoint}px)`,
			isMobile: `(max-width: ${this.breakpoint - 1}px)`
		}

    this.setAnimation()
  }

  setAnimation() {
		const {
			cardsContainer,
			cards
		} = this.DOM

		this.mm.add(this.responsiveObj, (context) => {
			const { isDesktop } = context.conditions

			if (isDesktop) {
				cardsContainer.forEach((container, i) => {
					const card = cards[i]
					let scale = 0.7 + 0.025 * i
					let rotation = -10

					container.tween = gsap.to(card, {
						scale,
						rotationX: rotation,
						transformOrigin: 'top center',
						ease: 'none',
						scrollTrigger: {
							id: i + 1,
							trigger: container,
							start: `top ${60 + 10 * i}`,
							// end: 'bottom 20%',
							end: 'bottom center',
							endTrigger: this.DOM.grid,
							scrub: 0.5,
							pin: container,
							pinSpacing: false,
						}
					})
				})
			} else {
				cardsContainer.forEach((container, i) => {
					const card = cards[i]

					container.tween = gsap.from(card, {
						xPercent: -100,
						opacity: 0,
						ease: 'none',
						scrollTrigger: {
							id: i + 1,
							trigger: container,
							scrub: 0.5,
							start: 'top 80%',
							end: 'top 30%',
						}
					})
				})
			}
		})
  }

  destroy() {
		if (this.DOM.cardsContainer && this.DOM.cardsContainer.length) this.DOM.cardsContainer.forEach(c => c.tween && c.tween.kill())
  }
}
