import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import { 
  header,
  footer,
  basicIntro,
  pageHeaderIntro, 
  pageSections 
} from './intro-animations'

export const pageIntro = (delay = 0.5) => {
  const { current } = viewStorage

  const basic_pages = current.classList.contains('Page') ||
                      current.classList.contains('NotFound')

  const tl = gsap.timeline({
    delay,
    defaults: { ease: 'power3.out', duration: 1 },
    onStart: () => {
      // Refresh ScrollTrigger
      ScrollTrigger.refresh()

      // Stop Scroll
      viewStorage.viewScroll.scroll.stop()

      // Reset scroll with timing for Chrome
      setTimeout(() => window.scrollTo(0, 0), 25)
    },
    onComplete: () => {
      // Refresh ScrollTrigger
      ScrollTrigger.refresh()

      domStorage.body.classList.remove('--loading')
      domStorage.body.classList.remove('--animating')
      domStorage.body.classList.remove('--intro')

      // Start Scroll
      viewStorage.viewScroll.scroll.start()

      // Header
      gsap.set(domStorage.header, { clearProps: 'all' })
    },
  })

  // Simple animations intro for basic pages
  if (basic_pages) {
    tl.add(basicIntro(), 0)
    tl.add(header(), 0.15)
  } else {
    tl.add(pageHeaderIntro(), 1)
    tl.add(header(), 1.5)
  }
}
